import * as prismicClient from '@prismicio/client';
import { cardsAdapter } from '@/slices/EditoCarousel/adapter';

import { asText, stripRichTextWrapperTag } from '@/utils/html';
import { routerLinks, offerLinks } from '@/utils/fetch-links';

const querySingleGuide = async (id, prismic, lang) =>
  await prismic.getByID(id, {
    fetchLinks: [...routerLinks, ...offerLinks],
    lang,
  });

const queryGuides = async (id, prismic, lang, additionalQueries = []) => {
  return await prismic.get({
    filters: [
      prismicClient.filter.at('document.type', 'page_guide'),
      prismicClient.filter.at('my.page_guide.parent', id),
      ...additionalQueries,
    ],
    orderings: [
      {
        field: 'my.page_guide.date',
        direction: 'desc',
      },
      {
        field: 'document.last_publication_date',
        direction: 'desc',
      },
    ],
    pageSize: 100,
    fetchLinks: [...routerLinks, ...offerLinks],
    lang,
  });
};

const lowercaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

const formatResults = (results) => {
  return results.map((result) => ({
    edito_carousel_card: { ...result, link_type: 'Document' },
  }));
};

const getGuides = async (guide, parent, grandParent, prismic, i18n) => {
  const lang = i18n.localeProperties.value.language;
  // 1. Get children
  const childrenGuides = await queryGuides(guide.id, prismic, lang, []);

  if (childrenGuides.results_size > 0) {
    return {
      cards: formatResults(childrenGuides.results),
      title: i18n.t('article.related-articles-read-more', {
        guide: lowercaseFirstLetter(asText(guide.title)),
      }),
    };
  }

  // 2. Else, try getting sibling
  const siblingGuides = parent
    ? await queryGuides(parent.id, prismic, lang, [
        prismicClient.filter.not('my.page_guide.uid', guide.uid),
      ])
    : null;

  if (siblingGuides?.results_size > 0) {
    return {
      cards: formatResults(siblingGuides.results),
      title: i18n.t('article.related-articles-read-more', {
        guide: lowercaseFirstLetter(asText(parent.title)),
      }),
    };
  }

  // 3. Else, try getting pibling (siblings of the parent)
  const piblingGuides = grandParent
    ? await queryGuides(grandParent.id, prismic, lang)
    : null;
  if (piblingGuides?.results_size > 0) {
    return {
      cards: formatResults(piblingGuides.results),
      title: i18n.t('article.related-articles-read-more', {
        guide: lowercaseFirstLetter(asText(grandParent.title)),
      }),
    };
  }
};

export default async (
  { primary },
  { pageData, i18n, prismic, enhancedLinkSerializer },
) => {
  const guide = {
    id: pageData.id,
    uid: pageData.uid,
    title: pageData.title,
  };
  const lang = i18n.localeProperties.value.language;
  const parent = pageData.parent.id
    ? {
        id: pageData.parent.id,
        uid: pageData.parent.uid,
        title: pageData.parent.data?.title,
      }
    : null;

  let grandParent = null;
  const grandParentId = pageData.parent.data?.parent?.id;

  if (grandParentId) {
    const grandParentData = await querySingleGuide(
      grandParentId,
      prismic,
      lang,
    );
    grandParent = {
      id: grandParentData.id,
      uid: grandParentData.uid,
      title: grandParentData.data?.title,
    };
  }

  const guides = await getGuides(guide, parent, grandParent, prismic, i18n);

  if (!guides) {
    return;
  }

  return {
    title:
      stripRichTextWrapperTag(primary.related_guides_title) || guides.title,
    withCarousel: false,
    cards: cardsAdapter(guides.cards, i18n, enhancedLinkSerializer),
  };
};
